import { Theme } from '@mui/material';

export const titleTopLineStyle = (theme: Theme) => {
  return {
    position: 'absolute',
    top: -14,
    display: 'inline-block',
    width: 20,
    height: 0,
    margin: '0 0 5px',
    background: 'transparent',
    border: `solid ${theme.palette.primary.main}`,
    borderWidth: '0 0 1.5px',
    [theme.breakpoints.up('md')]: {
      top: -16,
      width: 32
    }
  };
};
