import { Theme } from '@mui/material';
import { titleTopLineStyle } from './custom';

export const collectionOnePerRowVariant = (theme: Theme) => ({
  props: {
    variant: 'one-per-row'
  },
  style: {
    '& [class*="Section-root"] > [class*="Section-gridContainer"]': {
      '& > [class*="Section-gridItem"]': {
        flex: '0 0 100%'
      }
    },
    '& p': {
      color: theme.palette.text.secondary
    }
  }
});

export const collectionTwoPerRowVariant = (theme: Theme) => ({
  props: {
    variant: 'two-per-row'
  },
  style: {
    '& [class*="Section-root"] > [class*="Section-gridContainer"]': {
      '& > [class*="Section-gridItem"]': {
        flex: '0 0 50%',
        maxWidth: 1296 / 2
      }
    },
    '& p': {
      color: theme.palette.text.secondary
    }
  }
});

export const collectionThreePerRowVariant = (theme: Theme) => ({
  props: {
    variant: 'three-per-row'
  },
  style: {
    '& [class*="Section-gridItem"] > a': {
      textDecoration: 'none'
    },
    '& [class*="Section-root"] > [class*="Section-gridContainer"]': {
      'justifyContent': 'center',
      '& > [class*="Section-gridItem"]': {
        flex: '0 0 33.3%',
        [theme.breakpoints.down('md')]: {
          flex: '0 0 100%'
          // marginBottom: theme.spacing(2)
        }
      }
    },
    '& [class*="Collection-introText"]': {
      'position': 'relative',
      'paddingBottom': theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        paddingBottom: theme.spacing(5)
      },
      '& h2': {
        'paddingBottom': 0,
        'color': theme.palette.primary.main,
        'fontWeight': 200,
        '& b': {
          fontWeight: 700
        },
        '& strong': {
          fontWeight: 700
        }
      },
      '& h2 + h2': {
        'fontWeight': 700,
        '& + hr': {
          ...titleTopLineStyle(theme)
        }
      },
      '& h3': {
        ...theme.typography.h2,
        color: theme.palette.primary.main
      },
      '& h3 + h3': {
        'fontWeight': 700,
        '& + hr': {
          ...titleTopLineStyle(theme)
        }
      }
    },
    '& p': {
      color: theme.palette.text.secondary
    }
  }
});

export const collectionTilesVariant = (theme: Theme) => ({
  props: {
    variant: 'tiles'
  },
  style: {
    '& [class*="Section-root"] > [class*="Section-gridContainer"]': {
      'justifyContent': 'center',
      '& > [class*="Section-gridItem"]': {
        [theme.breakpoints.down('md')]: {
          flex: '0 0 33.3%'
        }
      }
    }
  }
});

export const collectionIconDarkThreeVariant = (theme: Theme) => ({
  props: {
    variant: 'icon-dark-three'
  },
  style: {
    'backgroundColor': theme?.scheme ? theme.palette.primary.main : theme.palette.text.secondary,
    '& [class*="Collection-introText"]': {
      'paddingBottom': theme.spacing(5),
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(10)
      },
      '& *': {
        color: theme?.scheme ? theme.palette.primary.contrastText : theme.palette.secondary.main
      },
      '& p': {
        maxWidth: 1060,
        margin: '0 auto',
        fontWeight: 400
      }
    },
    '& [class*="Section-root"] > [class*="Section-gridContainer"]': {
      'justifyContent': 'center',

      '& > [class*="Section-gridItem"]': {
        flex: '0 0 33.3%',
        [theme.breakpoints.down('md')]: {
          flex: '0 0 100%',
          [theme.breakpoints.down('md')]: {
            'flex': '0 0 100%',
            '&:not(:last-child)': {
              // marginBottom: theme.spacing(5)
            }
          }
        }
      }
    }
  }
});

export const collectionIconDarkFourVariant = (theme: Theme) => ({
  props: {
    variant: 'icon-dark-four'
  },
  style: {
    'backgroundColor': theme?.scheme ? theme.palette.primary.main : theme.palette.text.secondary,
    '& [class*="Collection-introText"]': {
      'paddingBottom': theme.spacing(5),
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(10)
      },
      '& *': {
        color: theme?.scheme ? theme.palette.primary.contrastText : theme.palette.secondary.main
      },
      '& p': {
        maxWidth: 1060,
        margin: '0 auto',
        fontWeight: 400
      }
    },
    '& [class*="Section-root"] > [class*="Section-gridContainer"]': {
      'justifyContent': 'center',

      '& > [class*="Section-gridItem"]': {
        flex: '0 0 25%',
        [theme.breakpoints.down('md')]: {
          'flex': '0 0 100%',
          '&:not(:last-child)': {
            // marginBottom: theme.spacing(5)
          }
        }
      }
    }
  }
});

export const logosVariant = (theme: Theme) => {
  return {
    props: {
      variant: 'logos'
    },
    style: {
      'textAlign': 'center',
      [theme.breakpoints.down('md')]: {
        'paddingLeft': theme.spacing(1),
        'paddingRight': theme.spacing(1),
        '& [class*="Collection-contentContainer"]': {
          padding: 0
        }
      },
      '& [class*="Collection-introText"]': {
        'paddingBottom': theme.spacing(7.5),
        '& h2': {
          ...theme.typography.h5,
          'fontSize': 18,
          'color': theme.palette.primary.main,
          '& b': {
            fontWeight: 600
          },
          '& strong': {
            fontWeight: 600
          }
        },
        '& h3': {
          ...theme.typography.h5,
          fontSize: 18,
          color: theme.palette.primary.main,
          paddingTop: theme.spacing(3.5)
        },
        '& h2 + h2': {
          fontWeight: 700
        },
        '& h3 + h3': {
          fontWeight: 700
        }
      },
      '& [class*="Section-root"] > [class*="Section-gridContainer"]': {
        // paddingLeft: theme.spacing(3),
        // paddingRight: theme.spacing(3),
        [theme.breakpoints.down('lg')]: {
          'display': 'grid',
          'gridTemplateColumns': 'repeat(3, 1fr)',
          'gridGap': theme.spacing(6),
          'maxWidth': 'calc(100% - 25vw)',
          'margin': '0 auto',
          'padding': 0,
          '& > [class*="MuiGrid-item"]': {
            padding: 0
          }
        },
        [theme.breakpoints.down('md')]: {
          maxWidth: 'calc(100% - 15vw)',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridGap: theme.spacing(3, 1)
        },
        [theme.breakpoints.down('sm')]: {
          maxWidth: '100%',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridGap: theme.spacing(4, 1)
        }
      },
      [theme.breakpoints.down('md')]: {
        '& [class*="MuiGrid-root"]': {
          padding: 0
        }
      },
      '& [class*="Card-root"]': {
        padding: 0
        // [theme.breakpoints.up('sm')]: {
        //   padding: theme.spacing(1)
        // },
        // [theme.breakpoints.up('xl')]: {
        //   padding: theme.spacing(2)
        // }
      },
      '& img': {
        maxHeight: theme.spacing(4),
        [theme.breakpoints.up('xl')]: {
          maxHeight: theme.spacing(7)
        }
      },
      '& [class*="MuiCardMedia-root"]': {
        maxHeight: theme.spacing(4),
        [theme.breakpoints.up('xl')]: {
          maxHeight: theme.spacing(7)
        }
      }
    }
  };
};

export const imageGridVariant = (_theme: Theme) => {
  return {
    props: {
      variant: 'image-grid'
    },
    style: {}
  };
};

export const iconSmallLeftCollectionVariant = (theme: Theme) => {
  return {
    props: {
      itemsVariant: 'icon-small-left'
    },
    style: {
      '& [class*="Collection-introText"]': {
        paddingBottom: theme.spacing(5)
      }
    }
  };
};

const variants = [
  collectionOnePerRowVariant,
  collectionTwoPerRowVariant,
  collectionThreePerRowVariant,
  collectionTilesVariant,
  collectionIconDarkThreeVariant,
  collectionIconDarkFourVariant,
  logosVariant,
  imageGridVariant,
  iconSmallLeftCollectionVariant
];

const createSectionVariants = (theme: Theme) => {
  return variants.map((creator) => creator(theme));
};

export default createSectionVariants;
