import { Theme } from '@mui/material';

export const defaultVariant = (theme: Theme) => ({
  props: {
    variant: 'default'
  },
  style: {
    h3: {
      ...theme.typography.h2,
      color: theme.palette.text.primary
    }
  }
});

const variants = [defaultVariant];

const createTextVariants = (theme: Theme) => {
  return variants.map((creator) => creator(theme));
};

export default createTextVariants;
