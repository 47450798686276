import { Theme } from '@mui/material';

export const heroDefaultBase = (theme: Theme) => ({
  '@media screen and (min-height: 1000px)': {
    'minHeight': '720px',
    '& [class*="Hero-contentContainer"]': {
      height: 'initial'
    }
  },
  '& .MuiContainer-root': {
    '& [class*="MuiTypography-overline"]': {
      display: 'block',
      marginBottom: theme.spacing(2),
      textIndent: theme.spacing(0.5),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(3)
      }
    },
    '& .MuiButton-root': {
      marginRight: theme.spacing(5)
    }
  },
  '& .MuiButton-contained': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    '&:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: `${theme.palette.primary.contrastText}80`,
    }
  },
  '& .MuiButton-outlined': {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    border: 'none',
    '&:hover': {
      border: 'none',
      color: theme.palette.primary.contrastText,
      backgroundColor: `${theme.palette.primary.main}80`,
    }
  }
});

export const defaultVariant = (theme: Theme) => ({
  props: {
    variant: 'default'
  },
  style: {
    ...heroDefaultBase(theme),
    '& [class*="Hero-contentContainer"]': {
      '@media (max-height: 700px) and (min-width: 1140px)': {
        transform: 'scale(0.9) translateY(10%) translateX(-70px)'
      },
      [theme.breakpoints.down('md')]: {
        '& > .MuiGrid-container': {
          'flexDirection': 'column-reverse',
          '& > .MuiGrid-container': {
            '& + div': {
              width: '100vw',
              textAlign: 'center'
            }
          }
        }
      }
    },
    '& h1': {
      fontWeight: 200
    },
    '& p': {
      ...theme.typography.h4
    }
  }
});

export const blogFeaturedVariant = (theme: Theme) => ({
  props: {
    variant: 'blog-featured'
  },
  style: {
    ...heroDefaultBase(theme),
    '& [class*="MuiTypography-overline"]': {
      ...theme.typography.h5
    },
    '& h1': {
      marginBottom: theme.spacing(1),
      fontWeight: 200
    },
    '& h2': {
      ...theme.typography.h1,
      marginBottom: theme.spacing(1),
      fontWeight: 200
    },
    '& p': {
      ...theme.typography.h4
    },
    '& [class*="Text-root"]': {
      marginBottom: theme.spacing(2)
    }
  }
});

export const resourceFeaturedVariant = (theme: Theme) => ({
  props: {
    variant: 'resource-featured'
  },
  style: {
    ...heroDefaultBase(theme),
    '& [class*="MuiTypography-overline"]': {
      ...theme.typography.h5
    },
    '& h1': {
      marginBottom: theme.spacing(1),
      fontWeight: 200
    },
    '& h2': {
      ...theme.typography.h1,
      marginBottom: theme.spacing(1),
      fontWeight: 200
    },
    '& p': {
      ...theme.typography.h4
    },
    '& [class*="Text-root"]': {
      marginBottom: theme.spacing(1)
    }
  }
});

export const gradientBackgroundVariant = () => ({
  props: {
    variant: 'gradient-background'
  },
  style: {
    background: 'linear-gradient(50deg, rgba(48,205,194,1) 0%, rgba(0,92,122,1) 100%)',
    h1: {
      color: 'white'
    },
    h2: {
      color: 'white'
    },
    p: {
      color: 'white'
    }
  }
});

export const popOutTextVariant = (theme: Theme) => ({
  props: {
    variant: 'pop-out-text'
  },
  style: {
    ...heroDefaultBase(theme),
    '&': {
      maxWidth: theme.breakpoints.values.xl,
      margin: 'auto',
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(15),
      marginBottom: theme.spacing(-8),
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        marginBottom: theme.spacing(0)
      }
    },
    '& [class*="Hero-backgroundRoot"]': {
      'width': 'calc(100% - 110px)',
      'maxWidth': 1330,
      'paddingTop': theme.spacing(8),
      'paddingBottom': theme.spacing(8),
      // Match contentContainer paddings
      'paddingLeft': theme.spacing(4),
      'paddingRight': theme.spacing(4),
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10)
      },
      '& img': {
        borderRadius: 10
      },

      [theme.breakpoints.down('md')]: {
        'width': '100%',
        'padding': 0,
        '& img': {
          borderRadius: 0
        }
      }
    },

    '& .MuiContainer-root': {
      '& > div:first-of-type': {
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row-reverse',
          marginTop: 0
        },
        [theme.breakpoints.down('md')]: {
          width: '100%',
          margin: 'auto'
        },
        '& > div:first-of-type': {
          'marginTop': 0,
          // 'marginRight': 22,
          'paddingRight': theme.spacing(6),
          'paddingBottom': theme.spacing(4),
          'backgroundColor': theme.palette.common.white,
          'boxShadow': '0px 4px 10px rgb(0 0 0 / 25%)',
          'borderRadius': 10,
          [theme.breakpoints.down('md')]: {
            margin: 'auto',
            width: '100%',
            padding: theme.spacing(2, 4, 2, 2)
          },
          '& h1': {
            ...theme.typography.h1,
            marginBottom: theme.spacing(1),
            fontWeight: 400,
            color: theme.palette.primary.main
          },
          '& h2': {
            ...theme.typography.h1,
            marginBottom: theme.spacing(1),
            fontWeight: 400,
            color: theme.palette.primary.main
          },
          '& p': {
            ...theme.typography.h4,
            fontWeight: 400,
            color: theme.palette.primary.main
          }
        }
      },
      '& .MuiButton-contained': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          color: theme.palette.primary.contrastText,
          backgroundColor: `${theme.palette.primary.dark}80`,
        }
      },
    },
    [theme.breakpoints.down('md')]: {
      // '& [class*="Hero-contentContainer"]': {
      //   alignSelf: 'flex-end'
      // },
      '& [class*="Media-root"]': {
        display: 'block'
      }
    },
    '& [class*="MuiButton-containedPrimary"]': {
      color: theme.palette.primary.contrastText
    }
  }
});

export const centeredShortVariant = (theme: Theme) => ({
  props: {
    variant: 'centered-short'
  },
  style: {
    ...heroDefaultBase(theme),
    'maxHeight': 248,
    'minHeight': 248,
    'backgroundColor': theme.palette.primary.main,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 292,
      minHeight: 292
    },
    '& h1': {
      color: theme.palette.primary.contrastText,
      fontWeight: 300,
      textAlign: 'center'
    },
    '& h2': {
      color: theme.palette.primary.contrastText,
      textAlign: 'center'
    },
    '& p': {
      color: theme.palette.primary.contrastText,
      textAlign: 'center'
    },
    '& .MuiContainer-root': {
      '& > div:first-of-type': {
        'justifyContent': 'center',
        '& > div:first-of-type': {
          paddingTop: 0,
          [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(2)
          }
        }
      }
    }
  }
});

const variants = [
  defaultVariant,
  blogFeaturedVariant,
  resourceFeaturedVariant,
  gradientBackgroundVariant,
  popOutTextVariant,
  centeredShortVariant
];

const createHeroVariants = (theme: Theme) => {
  return variants.map((creator) => creator(theme));
};

export default createHeroVariants;
